
import { Component, Vue, Prop } from 'vue-property-decorator';
import { KEY } from '@/inversify.keys';
import { inject } from '@/inversify';
import type { SettingsGeneralService } from '@/modules/settings/settings-general.service';

import Google from './googlemap.vue';
import OpenStreetMap from './osmap.vue';
import TencentMap from './tencentmap.vue';

@Component({
    components: {
        Google,
        OpenStreetMap,
        TencentMap,
    },
})
export default class Map extends Vue {
    @inject(KEY.SettingsGeneralService) private settingsGeneralService!: SettingsGeneralService;

    @Prop({
        type: Array,
        default: () => [],
    })
    markers!: google.maps.MarkerOptions[];

    @Prop({
        type: Object,
        default: () => ({
            lat: 0,
            lng: 0,
        }),
    })
    center!: { lat: number, lng: number };

    get mapProvider() {
        return this.settingsGeneralService.defaultFilters.mapProvider;
    }
}
