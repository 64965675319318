
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify.inject';
import { KEY } from '@/inversify.keys';
import ConfigService from '@/modules/config/config.service';
import { BaseMap, MultiMarker, InfoWindow } from 'tlbs-map-vue';

@Component({
    components: { BaseMap, MultiMarker, InfoWindow },
})
export default class TencentMap extends Vue {
    @inject(KEY.ConfigService) configService!: ConfigService;

    @Prop({
        type: Object,
        default: () => ({
            lat: 0,
            lng: 0,
        }),
    })
    center!: { lat: number, lng: number };

    @Prop({
        type: Array,
        default: () => [],
    })
    markersData!: google.maps.MarkerOptions[];

    isMapReady = false;
    closeButton: Element | null = null;

    currentMarkerOptions = {
        visible: false,
        content: '<div />',
        position: this.center,
        options: {
            offset: { x: 0, y: -30 },
        },
    };

    beforeDestroy() {
        this.closeButton?.removeEventListener('click', this.handleCloseClick);
    }

    get competitorStyles() {
        return {
            width: 28,
            height: 28,
            anchor: { x: 14, y: 28 },
            src: this.markersData[0].icon,
        };
    }

    get mapCenter() {
        return [this.center.lat, this.center.lng];
    }

    get styles() {
        const entries = this.markersData.map(marker => {
            const id = [marker.position?.lat, marker.position?.lng].join('-');

            return [id, {
                width: 28,
                height: 28,
                anchor: { x: 14, y: 28 },
                src: marker.icon,
            }];
        });

        return Object.fromEntries(entries);
    }

    get markers() {
        return this.markersData
            .map(marker => ({
                styleId: [marker.position?.lat, marker.position?.lng].join('-'),
                position: {
                    lat: marker.position?.lat || 0,
                    lng: marker.position?.lng || 0,
                },
                style: {
                    src: marker.icon,
                },
                tooltip: (marker as any).tooltip,
            }));
    }

    handleReady() {
        this.isMapReady = true;

        this.$nextTick(() => {
            const { refMap } = this.$refs;

            this.closeButton = (refMap as Vue).$el?.lastElementChild?.firstElementChild?.firstElementChild?.lastElementChild || null;
            this.closeButton?.removeAllListeners!('click');
            this.closeButton?.addEventListener('click', this.handleCloseClick);

            this.$emit('ready');
        });
    }

    handleCloseClick() {
        this.currentMarkerOptions = {
            ...this.currentMarkerOptions,
            visible: false,
        };
    }

    handleMarkerClick(marker: any) {
        if (!marker.geometry.tooltip) {
            this.currentMarkerOptions.visible = false;
            return;
        }

        this.currentMarkerOptions = {
            ...this.currentMarkerOptions,
            visible: true,
            content: marker.geometry.tooltip || '',
            position: marker.geometry.position,
        };
    }
}
